.serviceComponentContainer {
    display: flex;
    height: 80vh;
    width: 100%;
    align-items: center;
    justify-content: center;


}



.serviceComponentContent {

    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: space-between;
    align-items: center;
}

.serviceComponentContent p {

    color: white;
    width: 100%;
    font-size: 24px;
    font-family: 'DMMono-Medium';



}


.serviceComponentContentLeftSecond {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    width: 100%;

    text-wrap: wrap;
}

.serviceComponentContentRightSecond {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    width: 100%;

}

.serviceComponentContentRightSecond p {
    text-align: end;
}

.serviceComponentContentRightSecond h2 {

    font-size: 64px;
    color: var(--primary-color);

}

.serviceComponentContentRightFirst {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;



}

.serviceComponentContentLeftFirst {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 100%;



}

.serviceComponentContentRightFirst {

    width: 100%;
}

.serviceComponentContentLeftFirst h2 {

    color: var(--primary-color);
    font-size: 64px;

}

/* CSS */
.button-42 {
    background-color: rgba(255, 70, 23, 0.09);

    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;

    height: auto;
    overflow: hidden;
    padding: 0 20px;

    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    width: 300px;
    height: 300px;

    

    border-radius: 50%;



    border: none;
    animation: highLighted 1s ease-in-out infinite alternate;
    font-family: 'telegraf';
    font-size: 25px;
    border: var(--primary-color) 1px solid;


}

.button-42:hover {

    box-shadow: rgba(253, 76, 0, 0.5) 0px 0px 16px 16px;
    transform: scale(0.1);
    
}


@keyframes highLighted {
    0% {
        box-shadow: rgba(253, 76, 0, 0.5) 0px 0px 0px 0px;
        transform: scale(1);
        /* Aumenta el tamaño del botón al pasar el cursor */
    }

    100% {
        box-shadow: rgba(253, 76, 0, 0.5) 0px 0px 16px 16px;
        transform: scale(1.1);
    }
}

@keyframes highLighted_two {
    0% {
        box-shadow: rgba(253, 76, 0, 0.5) 0px 0px 0px 0px;
        transform: scale(1);
        /* Aumenta el tamaño del botón al pasar el cursor */
    }

    100% {
        box-shadow: rgba(253, 76, 0, 0.5) 0px 0px 16px 16px;
        transform: scale(1.02);
    }
}





.serviceComponentContent p {}



.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;

    border-radius: 8px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 100vh;
}

.closeButton {
    position: absolute;
    top: 5%;
    right: 5%;
    background: none;
    border: none;
    font-size: 25px;
    cursor: pointer;
    border: white 1px solid;
    border-radius: 50%;
    color: white;
    width: 3%;
    height: 5%;

}

/* Media queries para pantallas medianas */
@media (max-width: 1024px) {
    .serviceComponentContent {
        width: 95%;
    }

    .serviceComponentContent p {
        font-size: 24px;
    }

    .serviceComponentContentLeftSecond,
    .serviceComponentContentRightSecond,
    .serviceComponentContentRightFirst,
    .serviceComponentContentLeftFirst h2 {
        font-size: 56px;
    }

    .button-42 {
        width: 50%;
        height: 20vh;
        font-size: 22px;
    }

    .closeButton {
        width: 4%;
        height: 7%;
    }
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
    .serviceComponentContent {
        flex-direction: column;
        align-items: flex-start;
    }

    .serviceComponentContent p {
        font-size: 20px;
    }

    .serviceComponentContentLeftSecond,
    .serviceComponentContentRightSecond,
    .serviceComponentContentRightFirst,
    .serviceComponentContentLeftFirst h2 {
        font-size: 48px;
    }

    .button-42 {
        width: 70%;
        height: 15vh;
        font-size: 20px;
    }

    .closeButton {
        width: 5%;
        height: 8%;
    }
}
/* Media queries para pantallas medianas */
@media (max-width: 1024px) {
    .serviceComponentContent {
        width: 95%;
    }

    .serviceComponentContent p {
        font-size: 24px;
    }

    .serviceComponentContentLeftSecond,
    .serviceComponentContentRightSecond,
    .serviceComponentContentRightFirst,
    .serviceComponentContentLeftFirst h2 {
        font-size: 56px;
    }

    .button-42 {
        width: 200px;
        height: 200px;
        font-size: 22px;
    }

    .closeButton {
        width: 4%;
        height: 7%;
    }
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
    .serviceComponentContent {
        flex-direction: column;
        align-items: flex-start;
    }

    .serviceComponentContent p {
        font-size: 20px;
        margin-bottom: 10%;
    }

    .serviceComponentContentLeftSecond,
    .serviceComponentContentRightSecond,
    .serviceComponentContentRightFirst,
    .serviceComponentContentLeftFirst h2 {
        font-size: 48px;
    }

    .button-42 {
        width: 5%;
        height: 3vh;
        font-size: 20px;
        border-radius: 8px;
    }

    .closeButton {
        width: 5%;
        height: 8%;
    }
}

/* Media queries para pantallas extra pequeñas */
@media (max-width: 480px) {
.contentFiveContainer{

    height: 250vh;
}


    .serviceComponentContainer {
       
        height: 50vh;
        
    }

    .serviceComponentContent p {
        font-size: 18px;
    }

    .serviceComponentContentLeftSecond,
    .serviceComponentContentRightSecond,
    .serviceComponentContentRightFirst,
    .serviceComponentContentLeftFirst h2 {
        font-size: 40px;
    }

    .button-42 {
        width:60%;
        height: 5vh;
        font-size: 18px;
        animation: highLighted_two 1s ease-in-out infinite alternate;
    }

    .closeButton {
        width: 6%;
        height: 10%;
        font-size: 20px;
    }
}
