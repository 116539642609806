.contentSixContainer {
  display: flex;
  height: 120vh;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-bottom: 10%;


  
}

.contentSixLeft{
  width: 100%;


}



 

.contentSix {
  display: flex;
  align-items: center;
  width: 90%;
justify-content: center;
  flex-direction: column;

  margin-top: 30%;
}

.contentSix p {
  color: white;
}

.contentSix h2 {
  color: var(--primary-color);
  font-size: 96px;
  font-family: 'Telegraf';
}

.contactForm {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  background-color: transparent;
  
  padding: 36px;
  z-index: 2;


}

.formGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 90%;
  margin: 12px 0;
}

.formGroup label {
  margin-bottom: 4px;
  color: white;
  font-size: 18px;
  font-family: 'DMMono-Medium';
  margin-right:  16px ;
}

.formGroup input {
  height: 5vh;
  width: 100%;
  color: white;
  

  background-color:transparent;
  border: none;
  border-bottom: solid 1px white; /* Línea debajo del input */
  padding-left: 10px;
}

.formGroup input.asuntoInput {
  height: 10vh;
}

.contactForm button {
  background-color: var(--primary-color);
  width: 25%;
  height: 5vh;
  font-size: 24px;
  border: none;
  border-bottom: solid 1px black; 
  border-radius: 8px; 
  margin-top: 20px;
  color: white;
  font-family: 'DMMono-Medium';
}

.submitButton:hover {
  background-color: rgb(158, 50, 0)
}

.midContentForm {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.imgFormContainer {
 
  border-radius: 8px;
  width: 100%;
}

.splineContent{

  transform: scale(1.2);


}
@media (max-width: 1024px) {
  .contentSixContainer {
      height: auto;
      margin-bottom: 12%;
  }

  .contentSix h2 {
      font-size: 64px;
  }

  .contentSix {
      width: 95%;
      margin-top: 20%;
  }

  .contactForm {
      width: 75%;
      padding: 32px;
  }

  .contactForm button {
      width: 30%;
      font-size: 22px;
  }

  .formGroup input {
      height: 5vh;
  }

  .formGroup input.asuntoInput {
      height: 10vh;
  }
}


@media (max-width: 768px) {
  .contentSixContainer {
      height: auto;
      margin-bottom: 15%;
   
  }

  .contentSix h2 {
      font-size: 48px;
  }

  .contentSix {
      width: 100%;
      margin-top: 25%;
    
  }

  .contactForm {
      width: 80%;
      padding: 28px;
  }

  .contactForm button {
      width: 100%;
      font-size: 20px;
  }

  .formGroup input {
      height: 4.5vh;
  }

  .formGroup input.asuntoInput {
      height: 9vh;
  }


.imgFormContainer {
 

}



}


@media (max-width: 480px) {
  .contentSixContainer {
      height: auto; /* Ajusta la altura para adaptarse al contenido */
      margin-bottom: 20%; /* Aumenta el margen inferior para más espacio */
      flex-direction: column; /* Coloca los elementos en columna */
   
  }

  .contentSix h2 {
      font-size: 36px; /* Reduce el tamaño del título */
      text-align: left; /* Centra el texto */
      margin-left: 5%;
  }

  .contentSix {
      width: 100%;
      margin-top: 20%;
      
   
    
     
  }

  .midContentForm{
    overflow: hidden;
    display: flex;
    flex-direction: column;
 
  }

  .contactForm {
      width: 90%; /* Ajusta el ancho del formulario */
      padding: 20px; /* Reduce el padding */
      margin-bottom: 10%;
  }

  .contactForm button {
      width: 100%; /* Aumenta el ancho del botón */
      font-size: 18px; /* Reduce el tamaño del texto */
  }

  .formGroup input {
      height: 4vh;
      
  }

  .formGroup input.asuntoInput {
      height: 8vh;
  }

  .submitButton{
    width: 100%;
  }

  .imgFormContainer{
    height: 20vh;
  }
}
