.iconsContainer {
    display: flex;
    flex-direction: column;
    bottom: 40px;
    position: fixed;
    z-index: 1000;
    justify-content: space-between;
    width: 10%; /* Ajuste para evitar el espacio extraño */
    right: 10px; /* Alinea el contenedor a la derecha */
    align-items: flex-end;
  }
  
  .iconsContainer li {
    margin: 5px;
    list-style: none;
  }
  
  .whatsapp-button {
    background-color:var(--primary-color);
    color: white;
   
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 3px #222222;
    text-decoration: none;
    width: 50px;
    height: 50px;
    border-radius: 8px;
  }
  
  .whatsapp-button:hover {
    background-color: #5b1601;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .iconsContainer {
      width: 10%;
      align-items: center; /* Centra los iconos en pantallas móviles */
      right: 0; /* Alinea el contenedor al centro */
      bottom: 20px; /* Ajusta la posición desde abajo */
      margin-right: 0%;
    }
  
    .whatsapp-button {
      width: 60px; /* Ajusta el tamaño del botón en pantallas móviles */
      height: 60px; /* Ajusta el tamaño del botón en pantallas móviles */
      margin-right: 5%;
    }
  }
  
  @media (max-width: 480px) {
    .iconsContainer {
      bottom: 10px; /* Ajusta la posición desde abajo para pantallas muy pequeñas */
      margin-right: 5%;
    }
  
    .whatsapp-button {
  
      width: 40px; /* Ajusta el tamaño del botón en pantallas muy pequeñas */
      height: 40px; /* Ajusta el tamaño del botón en pantallas muy pequeñas */
      margin-right: 5%;
    }
  }
  