.contentThree {

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../../../images/diseno_portada.jpg');
    background-size: contain;
    overflow: hidden;
    

    mask-image: linear-gradient(black 80%,transparent)
    
   ; /* Asegura que el canvas y el overlay se posicionen correctamente */
  
   animation: animateBackgroundSize 15s ease infinite;
}

@keyframes animateBackgroundSize {
    0% {
        background-size: 98% 98%;
        background-position: center center;
    }
    50% {
        background-size: 105% 105%;
        background-position: center center;
    }
    100% {
        background-size: 98% 98%;
        background-position: center center;
    }
}
.sectionThreeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    z-index: 2; /* Asegura que el contenido esté por encima del overlay */
}

.sectionThreeContentRight {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 30%;
    position: relative;
    top:10vh;


}

.sectionThreeContentRight h2 {  
    font-family: 'Telegraf';
    font-size: 96px;
    color: white;
}

.sectionThreeContentRight p {
    font-family: 'DMMono-Medium';
    margin-bottom: 30%;
    color: white;
    font-size: 24px;
}



.sectionThreeContentLeft button:hover {
    transform: scale(1.1);
}

.sectionThreeContent {
    display: flex;
    flex-direction: row; 
    width: 100%;
}

/* Canvas style */
.canvasThree {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Envía el canvas al fondo */
    pointer-events: none; /* Asegura que el canvas no bloquee interacciones de desplazamiento */
}

@media (max-width: 1024px) {
    .sectionThreeContentRight h2 {
        font-size: 64px;
    }

    .sectionThreeContentRight p {
        font-size: 20px;
        margin-bottom: 25px;
    }

    .canvasThree {
        height: 60vh;
    }
}

@media (max-width: 768px) {
    .contentThree {
        height: auto;
        background-size: cover;
        background-position: center;
    }

    .sectionThreeContainer {
        width: 100%;
        padding: 20px;
    }

    .sectionThreeContent {
        flex-direction: column;
        align-items: center;
    }

    .sectionThreeContentRight {
        width: 90%;
        margin-left: 0;
        margin-top: 20px;
        align-items: center;
        text-align: center;
        top: 0;
    }

    .sectionThreeContentRight h2 {
        font-size: 48px;
    }

    .sectionThreeContentRight p {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .canvasThree {
        height: 50vh;
    }
}

@media (max-width: 480px) {
    .sectionThreeContentRight h2 {
        font-size: 36px;
        
    }

    .sectionThreeContentRight p {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .canvasThree {
        height: 40vh;
    }
}

