

.container {
  display: flex;
  flex-direction: column; /* Organiza los elementos en columnas */
  min-height: 100vh; /* Permite que el contenedor ocupe al menos la altura completa de la ventana */
}

.content {
  flex: 1; /* Permite que el contenido ocupe el espacio restante */
}

footer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
 
}

.footerContainer {
  width: 90%;
  border-radius: 8px;
  padding: 15px 0;
}

footer p {
  color: white;
  margin: 0;
  font-size: 14px;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

@media (max-width: 480px) {
  .footerContainer p {
      font-size: 8px;
  }
}
