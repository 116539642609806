/* src/ScrollIndicator.css */
.scroll-indicator-container {
    position: fixed;
    top: 45vh;
    right: 10px;
    width: 40px;
    height: 40px;
    z-index: 99;
    margin-right: 1%;
  }
  
  .scroll-indicator-svg {
    transform: rotate(-90deg);
    width: 100%;
    height: 100%;
  }
  
  .scroll-indicator-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.5;
  }
  
  .scroll-indicator-bar {
    fill: none;
    stroke: var(--primary-color);
    stroke-width: 2.8;
    stroke-linecap: round;
  }
  @media (max-width: 480px) {
    .scroll-indicator-container {
     
      width: 20px;
      height: 20px;
     
    }

  }


