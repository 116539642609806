.aboutUsContainer {
    display: flex;
    align-items: flex-start; /* Alinea el contenido al inicio */
    justify-content: center; /* Centrado opcional */
    flex-direction: column;
    width: 100%;
    margin-top: 10%;
    position: relative; /* Necesario para el z-index */
    margin-bottom: 25%;
    
}

.backgroundImage {
    position: absolute; /* Permite que se coloque detrás del contenido */
    height:70vh;
    top: 30vh; /* Ajusta esto para mover la imagen hacia abajo */
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../images/Earth from Space.webp'); /* Asegúrate que la ruta sea correcta */
    background-size: cover;
    background-position: center; /* Mantener el centrado */
    z-index: -1; /* Asegura que quede detrás del contenido */
    mask-image: linear-gradient(transparent,black 10%)
}

.accordion {
    width: 100%;
    max-width: 70%;
    margin: 20px auto;
    border-radius: 5px;
    overflow: hidden;

 
    z-index: 2; /* Asegura que el acordeón esté por encima */
}

.accordion-item {
    border-bottom: 1px solid #ddd;
}


.accordion-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    z-index: 3;
}

.accordion-header:hover {
    background-color: var(--primary-color);
}

.accordion-header h3 {
    margin: 0;
    font-size: 28px;
    color: white;
    font-family: 'Telegraf';
}

.accordion-header span {
    font-size: 32px;
    color: var(--primary-color);
}

/* Cambiar el color del span al hacer hover en el header */
.accordion-header:hover span {
    color: white;
}

.accordion-content {
    padding: 15px;
    color: white;
    border-top: 1px solid #ddd;
    animation: slideDown 0.3s ease-in-out;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    font-family: 'DMMono-Medium', sans-serif;

}

@keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 1000px;
      opacity: 1;
    }
}
