@font-face {
    font-family: 'Telegraf';
    src: url('./fonts/FontsFree-Net-Telegraf-UltraBold-800.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'DMMono-Medium';
    src: url('./fonts/DMMono-Medium.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Archivo';
    src: url('./fonts/Archivo-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  