



.image-sequencer img {
  display: block;
  width: 100%;
  height: auto;
  position: sticky;

}

.additional-content {
  display: none; /* Oculta el contenido adicional inicialmente */
  margin-top: 200vh; /* Ajusta según la altura necesaria */
}

.image-sequencer.complete .additional-content {
  display: block; /* Muestra el contenido adicional cuando la secuencia esté completa */
}
.contentFour {
  height: 260vh; /* Altura suficiente para permitir el scroll */
  
}

.image-sequencer {
  position: relative;
}

.image-sequencer img {
  display: block;
  width: 100%;
  height: auto;
  position: sticky;
  top: 0;
}


/* Estilos para teléfonos (pantallas pequeñas) */
@media (max-width: 480px) {
  .contentFour {
    
    height: 300vh; /* Altura ajustada para tablets */

  }



  .image-sequencer img {
  
    width: 100%;

  
    
  }

  .additional-content {
    margin-top: 150vh; /* Ajuste según la altura disponible en pantallas pequeñas */
  }
}

/* Estilos para iPads y tablets (modo retrato) */
@media (min-width: 481px) and (max-width: 768px) {
  .contentFour {
    height: 300vh; /* Altura ajustada para tablets */
  }

  .image-sequencer img {
    width: 100%;
    height: auto;
    top: 0;
  }

  .additional-content {
    margin-top: 180vh; /* Ajuste según la altura disponible en tablets */
  }
}

/* Estilos para iPads y tablets (modo paisaje) */
@media (min-width: 769px) and (max-width: 1024px) {
  .contentFour {
    height: 300vh; /* Mantiene la altura para pantallas grandes de tablets */
  }


  .image-sequencer img {
    width: 100%;
    height: auto;
  
  }

  .additional-content {
    margin-top: 200vh; /* Ajuste según la altura disponible en modo paisaje */
  }
}
