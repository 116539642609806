.contentTwo {
    background-color: transparent;
    width: 100%;
    height: 150vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), transparent), 
                      url('../../../images/pexels-efrem-efre-2786187-20808358_2.webp');
    background-size: cover;
}


@keyframes zoomBack {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.05);
    }
}


.canvasTwo {

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    /* Asegura que el canvas no bloquee interacciones de desplazamiento */

    width: 100%;
    z-index: 1;
    position: relative;
    top:-60vh;


}


.sectionTwoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 3;
 top:-60vh;
 padding-left: 10%;
 height: 80vh;

}

.sectionTwoContentRight {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10%;
    
}

.sectionTwoContentRight h2 {
    font-family: 'Telegraf';
    font-size: 96px;
    color: white;
}

.sectionTwoContentRight p {
    font-family: 'DMMono-Medium';
    margin-bottom: 30%;
    color: white;
    font-size: 24px;
}

.sectionTwoContentLeft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.sectionTwoContentRight img {
    width: 100%;
height: auto;
    position: relative;
    top:-70vh;
    left: 70vh;
    animation: zoomBack 3s ease-in-out infinite alternate;

    mask-image: linear-gradient(black 80%,transparent)
}

.sectionTwoContentLeft button {
    width: 30%;
    background-color: var(--primary-color);
   
    height: 5vh;
    font-family: 'DMMono-Medium';
    color: white;
    font-size: 24px;
}

.sectionTwoContentLeft button:hover {
    transform: scale(1.1);
}

.sectionTwoContent {
    display: flex;
    flex-direction: row;
    width: 100%;

}

/* Media queries para pantallas medianas */
@media (max-width: 1024px) {
    .canvasTwo {
        top: -80vh;
      
    
    }

    .sectionTwoContainer {
        position: relative;
        top: -140vh;
        padding-left: 5%;
        height: auto;
        flex-direction: column;
        padding-top: 20vh;
 
    }

    .sectionTwoContentRight {
        width: 90vw;
    
        flex-direction: column;
    }

    .sectionTwoContentRight h2 {
        font-size: 72px;
    }

    .sectionTwoContentRight p {
        font-size: 32px;
        margin-bottom: 20%;
    }

    .sectionTwoContentLeft {
        width: 80%;
        margin-top: 5%;
    }

    .sectionTwoContentRight img {
        position: relative;
        width: 100%;
        top: -10vh;
        left: 10vh;
    }

    .sectionTwoContentLeft button {
        width: 50%;
        font-size: 20px;
    }
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
    .canvasTwo {
        top:-60vh;
    }

    .sectionTwoContainer {
        top: 0vh;
        padding-left: 0;
        height: auto;
        padding-top: 10vh;
    }

    .sectionTwoContentRight {
        width: 100%;
        padding-top: 0;
    }

    .sectionTwoContentRight h2 {
        font-size: 60px;
    }

    .sectionTwoContentRight p {
        font-size: 18px;
        margin-bottom: 15%;
    }

    .sectionTwoContentLeft {
        width: 100%;
    }

    .sectionTwoContentRight img {
        width: 100%;
        top: -50vh;
        left: 50vh;
    }

    .sectionTwoContentLeft button {
        width: 70%;
        font-size: 18px;
    }
}

/* Media queries para pantallas extra pequeñas */
@media (max-width: 480px) {
    .canvasTwo {
       
        top:-50vh;
    
    }

    .contentTwo{
      
        height: 100vh;
    }

    .sectionTwoContainer {
        position: relative;
        top: -100vh;
        padding:10%;
  
    }

    .sectionTwoContentRight {
        width: 100%;
    }

    .sectionTwoContentRight h2 {
        font-size: 48px;
    }

    .sectionTwoContentRight p {
        font-size: 12px;
        margin-bottom: 10%;
    }

    .sectionTwoContentLeft {
        width: 100%;
    }

    .sectionTwoContentRight img {
      
        width: 100%;
        top: 0;
        left: 0;
        mask-image: 80%, transparent;
        
    }

    .sectionTwoContentLeft button {
        width: 90%;
        font-size: 16px;

    }
}
