.topContentContainer {
    display: flex;
    align-items: center;
    position: fixed;
    max-width: 100%;
    width: 100%;
    z-index: 100000;
    justify-content: space-around;
    padding: 1% 5%;
    border-radius: 8px;
    overflow: hidden;

}

.hamburger-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
    margin-right: 0;

}

.mainBannerImg {
    width: 20%;
    /* Tamaño de la imagen ajustado al 7% del contenedor */
    height: auto;
    /* Mantiene la proporción de la imagen */

  
  
}


.nav-links {
    display: flex;
    gap: 8%;
    width: 100%;
    justify-content: flex-end;
    align-items: center;    

padding-right: 5%;
 
    
    
}

.nav-links a {
    display: flex;
    text-decoration: none;
    color: white;
    font-size: 22px;
    font-weight: bold;
    font-family: 'DMMono-Medium', sans-serif;
   
    
    align-items: center;

}

.nav-links a:hover {
    color: #ff6600;
    /* Color de hover para los enlaces */
}

.contactButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    margin-left: 20%;

    background-color: var(--primary-color);

    border-radius: 8px;
    height: 3vh;


}

.contactButton:hover {
    color: white;
    background-color: white;
}


.topContentContainer {
    display: flex;
    align-items: center;
    position: fixed;
    max-width: 95%;
    z-index: 100000;
    justify-content: center;
    top: 2%;
    border-radius: 8px;
   
}

/* Estilos para pantallas pequeñas (teléfonos) */


/* Estilos para pantallas medianas (tabletas y dispositivos similares) */
@media (max-width: 768px) {
    .topContentContainer {
        
   
 
        top: 1%;
        max-width: 90%;
    }

    .hamburger-icon {
        font-size: 20px;
        margin-right: 10px;
    }

    .mainBannerImg {
        width: 30%;
        margin-right: 0;
        margin-left: 0;
    }

    .nav-links {
       
     
        gap: 5%;
        width: 100%;
     
        margin-top: 10px;
    }

    .nav-links a {
        font-size: 18px;
        width: auto;
    }

    .contactButton {
        width: 50%;
        margin-left: 0;
        margin-top: 10px;
    }
}

/* Estilos para pantallas grandes (iPads, tablets en modo horizontal, etc.) */
@media (min-width: 769px) and (max-width: 1024px) {
    .topContentContainer {
        left: 5%;
        top: 1%;
        max-width: 90%;
    }

    .hamburger-icon {
        font-size: 22px;
    }

    .mainBannerImg {
        width: 20%;
        margin-right: 15%;
    }

    .nav-links {
        gap: 2%;
    }

    .nav-links a {
        font-size: 20px;
    }

    .contactButton {
        width: 20%;
        margin-left: 10%;
        height: 4vh;
    }
}
@media (max-width: 480px) {
    .topContentContainer {
      
     
     
        max-width: 90%;
    }

    .hamburger-icon {
        font-size: 18px;
    }

    .mainBannerImg {
        width: 40%;
        height: auto;
    }

    .nav-links {
        
       
        gap: 10%;
        width: 100%;
    
        margin-top: 10px;
    }

    .nav-links a {
        font-size: 16px;
    }

    .contactButton {
        width: 100%;
        height: 3vh;
        margin-left: 10%;
        margin-top: 10px;
    }
}