.ModalContainter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-image: url('../../images/vecteezy_dark-blue-nebula-sparkle-purple-star-universe-in-outer-space_4918564.jpg');
    animation: animateBackgroundSize 15s ease infinite;
}

@keyframes animateBackgroundSize {
    0% {
        background-size: 98% 98%;
        background-position: center center;
    }
    50% {
        background-size: 120% 120%;
        background-position: center center;
    }
    100% {
        background-size: 98% 98%;
        background-position: center center;
    }
}

.ModalContentMain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;


}

.ModalContentRight {

    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    height: 90vh;


}

.ModalContentRight p {

    color: white;
    font-size: 24px;

}


.ModalContentRight h2 {
    color: white;

    font-size: 64px;
    font-family: 'telegraf';
}


.ModalContentLeft {

    display: flex;

    width: 100%;





}

.splineContent {}