.contentTotal {
 
    width: 100%;
    height: 200vh;
    display: flex;
    justify-content: center;
    background-color: transparent;
    background-image: url('../../../images/pexels-efrem-efre-2786187-20808358.webp');
    background-size: cover;
 mask-image: linear-gradient(black 80%,transparent)

}





.mainBannerImage{

    width: 30%;
    height: auto;
    margin-bottom: 1%;
    animation-duration: 4; /* don't forget to set a duration! */

}

.sectionOneMain {
    width: 95%;
    z-index: 2;
    /* Asegura que el contenido esté por encima del canvas */
    position: absolute;
    /* Asegura que el contenido se posicione relativamente al contenedor */
    top: 55vh;
    left: 5vh;
    
}

.sectionOneContainer {
    display: flex;
    width: 100%;
    position: absolute;
    z-index: 2;
    /* Asegura que el contenido esté por encima del canvas */

    justify-content: center;



}

.sectionOneContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;


}

.sectionOneContent p {
    font-size: 18px;
    width: 70%;
    text-align: center;
    color: white;
    font-family: 'DMMono-Medium';
    margin-bottom: 30%;
}




.sectionOneContent h1 {

    font-family: 'Telegraf';
    font-size: 200px;
    color: white;
    margin: 0%;

}



.sectionOneContent h2 {
    font-family: 'Telegraf';
    font-size: 80px;
    color: white;
    margin: 0%;
    position: relative;
    top:10vh;
}

.sectionOneContentRight button {
    width: 30%;
}



/* Canvas style */
.canvas {
    position: relative;
    top: 0vh;
    left: 0;
    z-index: 1;
    /* Envía el canvas al fondo */
    pointer-events: none;
    /* Asegura que el canvas no bloquee interacciones de desplazamiento */
    animation: fadeIn;
    animation-duration: 4; /* don't forget to set a duration! */

}

/* Media queries para pantallas medianas */
@media (max-width: 1024px) {


    
    .mainBannerImage {
        width: 60%;
    }

    .sectionOneContent p {
        font-size: 20px;
        width: 80%;
        margin-bottom: 20%;
    }

    .sectionOneContent h1 {
        font-size: 150px;
    }

    .sectionOneContent h2 {
        font-size: 60px;
        top: 8vh;
    }

    .sectionOneContentRight button {
        width: 50%;
    }


}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {

    .contentTotal{

        height: 100vh;
    }
    .mainBannerImage {
        width: 80%;
    }

    .sectionOneMain {
        top: 50vh;
        left: 3vh;
    }

    .sectionOneContent p {
        font-size: 18px;
        width: 90%;
        margin-bottom: 15%;
    }

    .sectionOneContent h1 {
        font-size: 120px;
    }

    .sectionOneContent h2 {
        font-size: 50px;
        top: 6vh;
    }

    .sectionOneContentRight button {
        width: 70%;
    }
}

/* Media queries para pantallas extra pequeñas */
@media (max-width: 480px) {

    .contentTotal{
        background-color: red;
    }

    .mainBannerImage {
        width: 40%;
    }

    .sectionOneMain {
        top: 45vh;
        left: 2vh;
    }

    .sectionOneContent p {
        font-size: 16px;
        width: 100%;
        margin-bottom: 10%;
    }

    .sectionOneContent h1 {
        font-size: 90px;
    }

    .sectionOneContent h2 {
        font-size: 20px;
        top: 5vh;
    }

    .sectionOneContentRight button {
        width: 90%;
    }
}
